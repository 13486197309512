import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;
  private isReady: Promise<void>;

  constructor(private storage: Storage) {
    this.isReady = this.init(); // Armazena a promise da inicialização
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async get(key: string) {
    await this.isReady; // Espera a inicialização ser completada
    return this._storage?.get(key);
  }

  async set(key: string, value: any) {
    await this.isReady; // Espera a inicialização ser completada
    this._storage?.set(key, value);
  }

  async remove(key: string) {
    await this.isReady; // Espera a inicialização ser completada
    this._storage?.remove(key);
  }

  async clear() {
    await this.isReady; // Espera a inicialização ser completada
    this._storage?.clear();
  }
}
